<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-10 col-sm-10">
                <h3>Channels</h3>
              </div>
              <div class="col-2 col-sm-2">
                <button v-if="checkPermission.create" class="btn btn-primary btn-block" @click="showModalForm">Add Channel</button>
              </div>
            </div>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="doSearch()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Channel Name</th>
                    <th>Display Name</th>
                    <th>Configuration WABA ID</th>
                    <th>Configuration Phone Number ID</th>
                    <th>Created</th>
                    <th colspan="4">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in channelList" :key="item.id">
                    <td>{{ item.type }}</td>
                    <td>{{ item.details?.display_name }}</td>
                    <td>{{ item.configuration?.business_id }}</td>
                    <td>{{ item.configuration?.phone_number_id }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>
                      <button v-if="checkPermission.read && (checkUser.role.name.toLowerCase() === 'admin' || (checkUser.role_id.toLowerCase() !== '2e673799-d373-4872-a3e1-05fbb3e419e4' && checkUser.id === item.created_by))" class="btn btn-outline-primary btn-sm mr-2" @click="getChannelProfile(item)">
                        <font-awesome icon="user-edit"/>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="this.dataTotal"
      @current-change="setPage"
      >
      </el-pagination>
    </div>

    <modal-add
      :show_form="showModalAdd"
      @onHide="showModalAdd = false"
    />

    <b-modal v-model="showModalEdit" title="Edit Document" hide-footer>
      <b-form ref="formAddDocument" @submit.prevent="createDocument" @reset="closeModal">
        <b-form-group id="companies" label="Companies:" label-for="company_id">
          <el-select class="w-100" id="company_id"
            filterable
            :filter-method="handlerSearchCompany" v-model="documentFormEdit.company_id">
            <el-option v-for="cp in company_list" :key="cp.id" :label="cp.name" :value="cp.id"></el-option>
          </el-select>
          <b-form-invalid-feedback :state="!v$.documentFormEdit.company_id.$invalid" v-if="v$.documentFormEdit.company_id.$errors[0]">
            {{ $t('validation.' + v$.documentFormEdit.company_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-file-name" label="File Name:" label-for="i-file-name">
          <b-form-input id="i-file-name" type="text" v-model="documentFormEdit.file_name" />
          <b-form-invalid-feedback :state="!v$.documentFormEdit.file_name.$invalid" v-if="v$.documentFormEdit.file_name.$errors[0]">
            {{ $t('validation.' + v$.documentFormEdit.file_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-category-name" label="Category:" label-for="i-category-name">
          <b-form-input id="i-category-name" type="text" v-model="documentFormEdit.category" />
          <b-form-invalid-feedback :state="!v$.documentFormEdit.category.$invalid" v-if="v$.documentFormEdit.category.$errors[0]">
            {{ $t('validation.' + v$.documentFormEdit.category.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="document-add-file_path" label="Attachment" label-for="document-add-file_path">
          <b-form-file ref="addDocumentFile" v-model="documentFormEdit.file_path"
            :accept="accepted_ext['document']"
            placeholder="Choose a file or drop it here." drop-placeholder="Drop file here."
          ></b-form-file>
          <div class="mt-3" v-if="documentFormEdit.full_url">
            Current attachment:<br>
            <a :href="documentFormEdit.full_url" target="_blank">View here</a>
          </div>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <!-- <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button> -->
          <!-- <el-button @click="updateDocument" :loading="loading.edit_document" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button> -->
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import moment from 'moment';
// import _ from 'lodash';
// import mime from 'mime';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import modalAdd from '../components/channels/AddChannel.vue';
import documentAPI from '../../api/companyDocuments';
import channelAPI from '../../api/channels';
import popupErrorMessages from '../../library/popup-error-messages';

export default {
  name: 'channelList',
  metaInfo: {
    title: 'Channel List',
  },
  components: {
    modalAdd,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      documentForm: {
        company_id: {
          required,
        },
        category: {
          required,
        },
        file_name: {
          required,
        },
        file_path: {
          required,
        },
      },
      documentFormEdit: {
        company_id: {
          required,
        },
        file_name: {
          required,
        },
        category: {
          required,
        },
      },
    };
  },
  data() {
    return {
      loading: {
        add_document: false,
        channelList: false,
        edit_document: false,
      },
      dataTotal: 0,
      dataSize: 0,
      page: 1,
      showModalAdd: false,
      showModalEdit: false,
      search: {
        search: this.$route.query.search || '',
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
      },
      channel_type: '',
      channelList: [],
      documentCount: 0,
      loaderStack: 0,
      loader: null,
      documentForm: {
        company_id: '',
        file_name: '',
        category: '',
        file_path: null,
      },
      documentFormEdit: {
        company_id: '',
        file_name: '',
        category: '',
      },
      accepted_ext: {
        document: '.pdf, .xls, .xlsx, .docx, .doc, .csv, .ppt, .pptx, .txt',
      },
      company_list: [],
      last_index: null,
      get_company_id: this.$store.state.company.activeCompany.id || '',
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.loadList();
      },
    },
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  async mounted() {
    this.showLoader();
    await this.loadList();
    await this.getCompanies();
    this.hideLoader();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.search.page = 1;
      this.search.per_page = 10;
      this.loadList();
    },
    setPage(val) {
      this.search.page = val;
      this.page = val;
      this.loadList();
    },
    showModalForm() {
      this.showModalAdd = true;
    },
    handlerSearchCompany(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getCompanies('company_name', value);
      }, 1000);
    },
    async loadList() {
      const loader = this.$loading.show();
      this.loading.channelList = true;
      await channelAPI.getAll(this.search.page, this.search.search)
        .then((res) => {
          this.dataTotal = res.data.total;
          this.dataSize = Math.ceil(this.dataTotal / 10);
          this.channelList = res.data.rows.map((dt) => {
            const data = dt;
            ['created_at'].forEach((v) => {
              data[`${v}_str`] = dt[v] ? moment(dt[v]).format('DD MMM YYYY, HH:mm') : '-';
            });
            return data;
          });
        }).catch((err) => {
          console.log(err);
          this.loading.channelList = false;
          loader.hide();
        });
      this.loading.channelList = false;
      loader.hide();
    },
    async getCompanies(search_by = '', search = '') {
      await documentAPI.getCompanies(1, search_by, search)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.company_list = res.data.rows;
        });
    },
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    resetFormAdd() {
      this.documentForm = {
        category: '',
        file_name: '',
        file_path: null,
        company_id: '',
      };
    },
    closeModal() {
      this.showModalAdd = false;
      this.showModalEdit = false;
      this.resetFormAdd();
      this.resetFormEdit();
    },
    getChannelProfile(ch) {
      this.$router.push(`/channel/${ch._id}/${ch.workspace_id}/profile`);
    },
  },
};
</script>
